.page {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.page[data-size='A4'] {
    width: 21cm;
    height: 29.7cm;
}
.page[data-size='A4'][data-layout='landscape'] {
    width: 29.7cm;
    height: 21cm;
}

.quotelogo {
    max-height: 40px;
    margin-top: 20px;
    margin-left: 10px;
}

.viewquote table td,
.viewquote table th {
    padding: 2px;
}

/* ul.list-view{
      list-style-type: none;
}
ul.list-view li{
      display: flex;
}
ul.list-view li::before{
       content: "";
       width: 3px;
       height: 3px;
       border: 3px solid black !important;
       border-radius: 50%;
       margin: 7px 5px 0 0;
} */

ul li {
      list-style-type: none;
    }

    ul li {
      display: flex;
      /* align-items: flex-start; */
    }

    .bullet li::before {
        content: 'l';
      font-family: 'ZapfDingbats';
      margin: 0.75em 1em 0 0;
      font-size: 50%;
    }

    .nestedBullet li::before {
            content: 'm';
      font-family: 'ZapfDingbats';
      margin: 0.75em 1em 0 0;
      font-size: 50%;
    }
