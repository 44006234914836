.tablecontainer {
    background-color: white;
}

.card {
    border-radius: 7px !important;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%) !important;
    border: none !important;
}
.appheader {
    background-color: #222d3b;
}

.metersTable {
    max-height: 20vh;
}

.metersTablespi {
    max-height: 23vh;
    font-size: 0.8rem;
}

.smtext {
    font-size: 0.8rem;
}

.quotesTable {
    max-height: 60vh;
}
.pricesTable {
    max-height: 20vh;
}

thead th {
    background-color: white;
    position: sticky;
    top: -1px;
    box-shadow: inset 0 1px 0 #dee2e6, inset 0 -1px 0 #dee2e6;
}

.vl {
    border-left: 2px solid rgb(158, 156, 156);
    height: 1.5rem;
}

/*
thead th {
    background: white;
    position: sticky;
    top: 0;
    box-shadow: inset 0 1px 0 red, inset 0 -2px 0 red;
}*/
@media (max-width: 767px) {
    .quotelistHeader .text-right {
        text-align: left !important;
    }
}

@font-face {
    font-family: 'Dancing Script';
    src: url('DancingScript-Regular.ttf') format('truetype');
}

.modal-90w {
    width: 90vw;
    max-width: 90vw !important;
}

.Graphcontainer {
    padding-bottom: 3rem;
}
